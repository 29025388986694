import React, { Component, createRef } from "react";
import Api from "../../Services/api";
import { ToastContainer, toast } from "react-toastify";
import Select from 'react-select';
import moment from "moment";
import Autocomplete from "react-google-autocomplete";

export class CreateNewTrip extends Component {

	


	uniform_types = [
		{'label' : "Uniform", 'value' : 1},
		{'label' : "No Uniform", 'value' : 0}
	]


	componentDidMount = async()=>{
		this.getVehicleTypes();
		this.getVehicleModels();
		this.getTransmissionTypes();
		this.setMinimumDate();
	}


	setMinimumDate = async()=>{
		var min_date = await moment().add(1, 'hours').format("DD-MM-YYYY hh:mm A");
		this.setState({'min_start_date' : moment(min_date, '"DD-MM-YYYY hh:mm A"').format("YYYY-MM-DD")});
	}

	getVehicleTypes  = async() =>{
		var response = await Api.GetRequest({}, 'vehicle-types');
		if(response.status == 200){
			var vehicle_types = [];
			
			response.vehicle_types.forEach(async (vehicle) => {
				await vehicle_types.push({'value' : vehicle.id, 'label' : vehicle.name});
			});
			this.setState({'vehicle_types' : vehicle_types});

		}
	}


	getVehicleModels  = async() =>{
		var response = await Api.GetRequest({}, 'vehicle-models');
		if(response.status == 200){
			var vehicle_models = [];
			response.vehicle_models.forEach(async (vehicle) => {
				await vehicle_models.push({'value' : vehicle.id, 'label' : vehicle.name});
			});
			this.setState({'vehicle_models' : vehicle_models});
		}
	}


	getTransmissionTypes = async() =>{
		var response = await Api.GetRequest({}, 'transmission-types');
		if(response.status == 200){
			var transmission_types = [];
			response.transmission_types.forEach(async (tranmission) => {
				await transmission_types.push({'value' : tranmission.id, 'label' : tranmission.name});
			});
			this.setState({'transmission_types' : transmission_types});
		}
	}


	trip_types = [
		{ 'value': 0, 'label': 'One way' },
		{ 'value': 1, 'label': 'Round' },
	];


	station_types = [
		{ 'value': 0, 'label': 'In station' },
		{ 'value': 1, 'label': 'Out Station' },
	];


	constructor(props) {
		super(props);
		this.state = {
			"vehicle_types" : [],
			"vehicle_models" : [],
			"transmission_types" : [],
			"vehicle_type" : {},
			"vehicle_model" : {},
			"transmission_type" : {},
			"uniform_type" : "",
			"trip_type": "",
			"station_type": "",
			"mobile": "",
			"pickup_address": "",
			"pickup_latitude": 17.4401,
			"pickup_longitude": 78.3489,
			"destination_address": "",
			"destination_latitude": 17.3317,
			"destination_longitude": 78.5754,
			"min_start_date" : "",
			"start_date": "",
			"start_time": "",
			"end_date": "",
			"end_time": "",
			"instructions": ""
		};
		this.start_time_ref = createRef()
	}




	resetState = async()=>{
		this.setState({
			"vehicle_type" : {},
			"vehicle_model" : {},
			"transmission_type" : {},
			"uniform_type" : "",
			"trip_type": "",
			"station_type": "",
			"mobile": "",
			"pickup_address": "",
			"pickup_latitude": null,
			"pickup_longitude": null,
			"destination_address": "",
			"destination_latitude": null,
			"destination_longitude": null,
			"start_date": "",
			"start_time": "",
			"end_date": "",
			"end_time": "",
			"instructions": ""
		});
	}




	createTrip = async()=>{
		var data = {
			"vehicle_model_id": this.state.vehicle_model.value,
			"vehicle_model_name": this.state.vehicle_model.label,
			
			"vehicle_type_id": this.state.vehicle_type.value,
			"vehicle_type_name": this.state.vehicle_type.label,

			"transmission_type_id": this.state.transmission_type.value,
			"transmission_type_name": this.state.transmission_type.label,

			"trip_type": this.state.trip_type,
			"mobile": this.state.mobile,
			"pickup_address": this.state.pickup_address,
			"pickup_latitude": this.state.pickup_latitude,
			"pickup_longitude": this.state.pickup_longitude,
			"destination_address": this.state.destination_address,
			"destination_latitude": this.state.destination_latitude,
			"destination_longitude": this.state.destination_longitude,
			'start_date' : moment(this.state.start_date).format('YYYY-MM-DD'),
            'start_time' : moment(this.state.start_time, 'HH:m').format('hh:mm a'),
            'end_date' : moment(this.state.end_date).format('YYYY-MM-DD'),
            'end_time' : moment(this.state.end_time, 'HH:m').format('hh:mm a'),
			"station_type": this.state.station_type,
			"instructions": this.state.instructions,
			"uniform_type" : this.state.uniform_type
		};
		var response = await Api.PostRequest(data, "trips");
		if (response.status === 200) {
			toast.success("Sucessfully create");
			this.resetState();
		} 
		else {
			toast.error(response.message);
		}
	};



	selectAddress = async(place, type)=>{
		if (type == 0){
			this.setState({
				'pickup_address' : place.formatted_address,
				'pickup_latitude' : place.geometry.location.lat(),
				'pickup_longitude' : place.geometry.location.lng(),
			})
		}
		else{
			this.setState({
				'destination_address' : place.formatted_address,
				'destination_latitude' : place.geometry.location.lat(),
				'destination_longitude' : place.geometry.location.lng(),
			})
		}
		
	}


	openStartTime = async()=>{
		if(this.state.start_date == ""){
			toast.error("Please select start date");
		}
	}

	validateStartDateTime = async()=>{
        if(this.state.start_date && this.state.start_time){
			console.log(this.state.start_date)
            var start_date_time = this.state.start_date+" "+this.state.start_time;
            var current = moment().add(1, 'hours').format("YYYY-MM-DD HH:m")
            if(moment(start_date_time, "YYYY-MM-DD HH:m").isAfter(moment(current, "YYYY-MM-DD HH:m"))){
            
            }
            else{
                await toast.error("Start time should be 1 hour greater than present time");
                this.setState({'start_time' : ""})
            }
        }
        this.setState({'end_date' : "", "end_time" : ""})
        
    }

   
    validateEndDateTime = async()=>{
		if(this.state.end_date && this.state.end_time){
			var start_date_time = this.state.start_date+" "+this.state.start_time;
			var end_date_time = this.state.end_date+" "+this.state.end_time;
			start_date_time = moment(start_date_time, "YYYY-MM-DD HH:m").add(1, 'hours').format("YYYY-MM-DD HH:m")
			if(moment(start_date_time, "YYYY-MM-DD HH:m").isAfter(moment(end_date_time, "YYYY-MM-DD HH:m"))){
				await toast.error("End time should be 1 hour greater than start time");
				this.setState({'end_time' : ""})
			}
			else{
				
			}
		}       
    }


	render() {
		return (
			<div>
				<div className="pcoded-main-container">
					<div className="pcoded-content" style={{ textAlign: "left" }}>
						<div className="page-header">
							<div className="page-block">
								<div className="row align-items-center">
									<div className="col-md-12">
										<div className="page-header-title">
											<h5 className="m-b-10">Coupons</h5>
										</div>
										<ul className="breadcrumb">
											<li className="breadcrumb-item">
												<a href="index.html">
													<i className="feather icon-home"></i>
												</a>
											</li>
											<li className="breadcrumb-item">
												<a href="#!">Membership</a>
											</li>
											<li className="breadcrumb-item">
												<a href="#!">Coupons</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-xl-12">
								<div className="card">
									<div className="card-body">
										<div id="collected-chart">
											<div className="reservation-page-section">
												<div className="container">

													<div className="reservation-detais form-inner">
														<div className="row form-wrap">

															<div className="col-lg-6 col-md-6 col-sm-6 col-6 my-2 form-group">
																<label>Phone</label>
																<input type="tel" name="phone" id="phone" placeholder="phone No." style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} value={this.state.mobile} onChange={(event) => this.setState({ mobile: event.target.value })} />
															</div>

															<div className="col-lg-6 col-md-6 col-sm-6 col-6 my-2 form-group">
																<label>Vehicle type</label>
																<Select value={this.state.vehicle_type} options={this.state.vehicle_types} onChange={(event) => {this.setState({vehicle_type : event})}} />
															</div>

															<div className="col-lg-6 col-md-6 col-sm-6 col-6 my-2 form-group">
																<label>Vehicle Model</label>
																<Select value={this.state.vehicle_model} options={this.state.vehicle_models} onChange={(event) => {this.setState({vehicle_model : event})}} />
															</div>


															<div className="col-lg-6 col-md-6 col-sm-6 col-6 my-2 form-group">
																<label>Tranmission Type</label>
																<Select value={this.state.transmission_type} options={this.state.transmission_types} onChange={(event) => {this.setState({transmission_type : event})}} />
															</div>


															<div className="col-lg-6 col-md-6 col-sm-6 col-6 my-2 form-group">
																<label>Trip type</label>
																<Select options={this.trip_types} onChange={(event) => { this.setState({ trip_type: event.value }) }} />
															</div>

															<div className="col-lg-6 col-md-6 col-sm-6 col-6 my-2 form-group">
																<label>Station</label>
																<Select options={this.station_types} onChange={(event) => { this.setState({ station_type: event.value }) }} />
															</div>


															<div className="col-lg-6 col-md-6 col-sm-6 col-12 my-2 form-group">
																<label>Pickup Location</label>
																<Autocomplete
																	apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
																	onPlaceSelected={(place) => {
																		this.selectAddress(place, 0);
																	}}
																	options={{
																		types: ["(regions)"],
																		componentRestrictions: { country: "in" },
																	}}
																	defaultValue=""
																	style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }}
																/>
																{/* <input type="text" name="pickup_address" id="pickup_address" placeholder="Pickup Address" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} value={this.state.pickup_address} onChange={(event) => this.setState({ pickup_address: event.target.value })} /> */}
															</div>
															
															<div className="col-lg-6 col-md-6 col-sm-6 col-12 my-2 form-group">
																<label>Destination Location</label>
																<Autocomplete
																	apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
																	onPlaceSelected={(place) => {
																		this.selectAddress(place, 1);
																	}}
																	options={{
																		types: ["(regions)"],
																		componentRestrictions: { country: "in" },
																	}}
																	defaultValue=""
																	style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }}
																/>
															</div>
															
															<div className="col-lg-6 col-md-6 col-sm-6 col-12 my-2 form-group">
																<label>Start Date</label>
																<input type="date" min={this.state.min_start_date} name="start_date" placeholder="Start Date" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} value={this.state.start_date} onChange={(event) => this.setState({ start_date: event.target.value }, ()=>{this.validateStartDateTime()})} />
															</div>

															<div className="col-lg-6 col-md-6 col-sm-6 col-12 my-2 form-group">
																<label>Start Time</label>
																<input type="time" disabled={this.state.start_date == ""} name="start_time" id="start_time" placeholder="" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} value={this.state.start_time}  onChange={(event) => {this.setState({ start_time: event.target.value }, ()=>{this.validateStartDateTime()});}}/>
															</div>

															<div className="col-lg-6 col-md-6 col-sm-6 col-12 my-2 form-group">
																<label>End Date</label>
																<input type="date" min={this.state.start_date} disabled={this.state.start_date == "" || this.state.start_time == ""} name="end_date" placeholder="Trip Date" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} value={this.state.end_date} onChange={(event) => this.setState({ end_date: event.target.value })} />
															</div>
															
															<div className="col-lg-6 col-md-6 col-sm-6 col-12 my-2 form-group">
																<label>End Time</label>
																<input type="time" disabled={this.state.end_date == ""} name="end_time" id="end_time" placeholder="" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} value={this.state.end_time} onChange={(event) => this.setState({ end_time: event.target.value }, ()=>{this.validateEndDateTime()})} />
															</div>

															<div className="col-lg-6 col-md-6 col-sm-6 col-6 my-2 form-group">
																<label>Uniform</label>
																<Select options={this.uniform_types} onChange={(event) => {this.setState({ uniform_type: event.value }) }} />
															</div>
															
															<div className="col-lg-12 col-md-12 col-sm-12 col-12 my-2 form-group">
																<textarea name="instruction" placeholder=" " style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} value={this.state.instructions} onChange={(event) => this.setState({ instructions: event.target.value })} />
															</div>
														</div>
													</div>


													<center>
														<button type="button" onClick={() => { this.createTrip()}} className="btn btn-primary">Create Trip</button>
													</center>

												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default CreateNewTrip;
