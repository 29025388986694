import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Api from "../../Services/api";
import { ToastContainer, toast } from "react-toastify";
import DataTable from "react-data-table-component";
import moment from "moment";


export class TransmissionType extends Component {

  componentDidMount = async () => {
    this.getTransmissionType();
  };


  getTransmissionType = async () => {
    var response = await Api.GetRequest({}, "transmission-types");
    if (response.status == 200) {
      console.log(response);
      this.setState({ transmissiontypes: response.transmission_types});
    } 
    else {
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      transmissiontypes: [],
    };
  }


  render() {

    const columns =[
			{
				name :"S.NO.",
				selector: (row, index) => index +1,
				sortable :true

			},
			{
				name :"Name",
				selector: row => row.name,
				sortable :true

			},
			
			{
				name :"Created At",
				selector: row => moment(row.created_at).format('111'),
				sortable :true

			},

      {
        name : "Action",
        cell : row =>(
          
          <div className="text-right" style ={{display:"flex"

          }}>
            <Link to={`/vechicles/model-edit/${row.id}`} className="btn btn-info btn-sm mx-2" style={{whiteSpace:"nowrap"}}>
               <i className="feather icon-edit"></i>&nbsp;Edit{" "}
            </Link>
            <button className="btn btn-danger btn-sm mx-2" style={{whiteSpace:"nowrap"}}>
              <i className="feather icon-trash-2"></i>&nbsp;Delete{" "}
            </button>
            

          </div>
        )
      }
		]



    return (
      <div>
        <div className="pcoded-main-container" style={{ textAlign: "left" }}>
          <div className="pcoded-content">
            <div className="page-header">
              <div className="page-block">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <div className="page-header-title">
                      <h5 className="m-b-10">Transmission Type List</h5>
                    </div>
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="index.html">
                          <i className="feather icon-home"></i>
                        </a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="#!">vechicles</a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="#!"> Transmission Type:List</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <div className="dt-responsive table-responsive">
                    <DataTable 
                          columns ={columns}
                          data ={this.state.transmissiontypes}
                          pagination
                          selectableRows
                          expandableRows
                          expandableRowsComponent ={({data}) =>(
                          <div>
                            
                            <p><strong>Name :</strong>{data.name}</p>
                            
                            
                           

                          </div>

                          )}
                        
                       />
                      {/* <table id="full-row-reorder" className="table table-striped table-bordered nowrap">
                        <thead>
                          <tr>
                            <td colSpan="7" style={{ border: "none" }}>
                              <input type="search" name="search" id="search" placeholder="search" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} />
                            </td>
                          </tr>
                        </thead>

                        <thead>
                          <tr>
                            <th style={{ border: "none" }}>Transmission Type ID</th>
                            <th style={{ border: "none" }}>Name</th>

                            <th style={{ border: "none" }}>Created At</th>
                            <th style={{ border: "none" }}>update At</th>
                            <th style={{ border: "none" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.state.transmissiontypes.map((item, index) => {
                          return (
                            <tr>
                              <td style={{ verticalAlign: "middle" }}>{index + 1}</td>
                              
                              <td style={{ verticalAlign: "middle" }}>{item.name}</td>
                              <td style={{ verticalAlign: "middle" }}>created At </td>
                              <td style={{ verticalAlign: "middle" }}> Updated At</td>  
                              
                              <td className="text-right">
                                <Link to="" className="btn btn-info btn-sm mx-2">
                                  <i className="feather icon-edit"></i>&nbsp;Edit{" "}
                                </Link>
                               
                              </td>
                            </tr>
                          );
                        })}
                        </tbody>
                      </table> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    );
  }
}

export default TransmissionType;
